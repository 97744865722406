const DEBUG_MODE = process.env.NODE_ENV === 'development'

let serverPath = ''

// 后端服务器链接
const local = window.location.origin
let serverUrl = ''
let str_key = '9eb92f077af1b92ef84dae8316680c01'

if (window.location.host == 'www.pbsartsfoundation.com') {
  serverUrl = window.location.protocol + '//www.pbsartsfoundation.com'
  serverPath = 'prod'
} else {
  serverUrl = window.location.protocol + '//pbs-test.luckevent.com'
  serverPath = 'test'
}

// oss 链接
const oss = {
  url: serverUrl,
  last: ''
}

const dateFormat = (fmt, date) => {
  let ret;
  const opt = {
    "Y+": date.getFullYear().toString(),        // 年
    "M+": (date.getMonth() + 1).toString(),     // 月
    "D+": date.getDate().toString(),            // 日
    "h+": date.getHours().toString(),           // 时
    "m+": date.getMinutes().toString(),         // 分
    "s+": date.getSeconds().toString()          // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  }
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt)
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
    }
  }
  return fmt
}

export {DEBUG_MODE, dateFormat, oss, str_key, serverPath, serverUrl}
