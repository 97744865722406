import Vue from 'vue'
import Router from 'vue-router'
import project from './../project/index'

const Home = () => import('./../views/home.vue')
const HomeEng = () => import('./../views/home_eng.vue')
const Exhibition = () => import('../views/exhibition')
const ExhibitionDetail = () => import('../views/exhibition/detail.vue')
const ExhibitionBackups = () => import('../views/exhibition/backups.vue')
const News = () => import('../views/news')
const NewsDetail = () => import('../views/news/detail.vue')
const Press = () => import('../views/press')
const PressDetail = () => import('../views/press/detail.vue')
const Aboutus = () => import('../views/aboutus')
const Contactus = () => import('../views/contactus')
const Collection = () => import('../views/collection')
const CollectionDetail = () => import('../views/collection/detail.vue')
const Activity = () => import('../views/activity')
const ActivityDetail = () => import('../views/activity/detail.vue')
const Login = () => import('./../views/login.vue')

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: `/${project.mid}/`,
  routes: [
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/home_eng',
      name: 'home_eng',
      component: HomeEng,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/exhibition',
      name: 'exhibition',
      component: Exhibition,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/exhibition_detail',
      name: 'exhibition_detail',
      component: ExhibitionDetail,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/exhibition_backups',
      name: 'exhibition_backups',
      component: ExhibitionBackups,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/news',
      name: 'news',
      component: News,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/news_detail',
      name: 'news_detail',
      component: NewsDetail,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/press',
      name: 'press',
      component: Press,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/aboutus',
      name: 'aboutus',
      component: Aboutus,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/contactus',
      name: 'contactus',
      component: Contactus,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/collection',
      name: 'collection',
      component: Collection,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/collection_detail',
      name: 'collection_detail',
      component: CollectionDetail,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/activity',
      name: 'activity',
      component: Activity,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/activity_detail',
      name: 'activity_detail',
      component: ActivityDetail,
      meta: {
        loginAuth: false
      }
    },
    {
      path: '/',
      redirect: '/home'
    },
    {
      path: '*',
      redirect: '/home'
    },
  ]
})
