import Vue from 'vue'
import './plugins/mint.js'
import App from './App.vue'
import './style/public.scss'
import router from './router/index'
import store from './store/index'
import Axios from './api/index'
import project from './project/index'
import UAParser from 'ua-parser-js'
import 'animate.css'
import ElementUI from 'element-ui';
import "element-ui/lib/theme-chalk/index.css";

Vue.prototype.isNull = function (str) {
  if (str === '' || str === null || str === 'null' || str === false || str === 'false' || str === undefined || str === 'undefined' || str === 'NaN') {
    return false;
  }
  return true;
}

Vue.config.productionTip = false
Vue.use(ElementUI);

Vue.prototype.$http = Axios
Vue.prototype.meetingAlias = project.mid
import Wechat, { wx } from './api/wechat'

const WechatSDK = new Wechat()
Vue.prototype.$shareWeixin = WechatSDK.share
Vue.prototype.$prohibit = WechatSDK.prohibit
Vue.prototype.$wx = wx

Vue.prototype.$scrollOnePx = () => {
  document.querySelectorAll('input[type="text"]').forEach(event => {
    event.onblur = () => {
      const scroll_top = document.documentElement.scrollTop
      if (scroll_top > 0) {
        window.scrollTo(0, scroll_top - 1)
      } else {
        window.scrollTo(0, scroll_top + 1)
      }
    }
  })
}

const os = new UAParser().getOS().name.toLowerCase()

if (os === 'ios') {
  let iosSignatureUrl = window.location.protocol + '//' + window.location.host + window.location.pathname + window.location.search
  localStorage.setItem('iosSignatureUrl', iosSignatureUrl)
}

Vue.prototype.$getUserInfo = (callback, refresh = false) => {
  const user = store.state.user
  if (user.id && !refresh) {
    if (callback) callback(user)
    return
  }
  Axios.getUserInfo({})
    .then(r => {
      if (r.code == 1) {
        store.commit('userEdit', r.res)
        if (callback) callback(r.res)
      }
    })
    .catch(e => {
      console.error(e)
    })
}

const shareObj = {
  title: '',
  desc: '',
  link: '',
  imgUrl: ''
}

let timer = null
router.afterEach(() => {
  if (timer) {
    clearTimeout(timer)
    timer = null
  }

  timer = setTimeout(() => {
    WechatSDK.share(shareObj)
  }, 1000)
})

router.beforeEach((to, from, next) => {
  const token = to.query.api_token
  const tokenStr = `${project.mid}token`
  const openid = to.query.openid
  const openidStr = `${project.mid}openid`
  if (token) {
    localStorage.setItem(tokenStr, token)
  }
  if (openid) {
    localStorage.setItem(openidStr, openid)
  }
  if (to.meta.loginAuth) {
    const token = localStorage.getItem(tokenStr)
    if (!token) {
      window.location.href = window.location.protocol + '//' + window.location.host + '/' + project.mid + '/login'
      return
    }
  }

  /* eslint-disable */
  if (to.path) {
    //_hmt.push(['_trackPageview', `/${project.mid}/${to.fullPath}`])
  }
  /* eslint-disable */

  window.scrollTo(0, 0)
  next()

})

Vue.mixin({
  computed: {
    user() {
      return store.state.user
    },
    isiOS() {
      return os === 'ios'
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
